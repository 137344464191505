<script>
import appConfig from "@/app.config";

/**
 * Maintenance component
 */
export default {
  page: {
    title: "Cancel",
    meta: [
      {
        name: "cancel",
        content: appConfig.description,
      },
    ],
  },
  created(){
    setTimeout( () => this.$router.push({ path: '/'}), 10000);
  }
};
</script>

<style scoped>
.success-text {
  color: #006E33;
}
</style>

<template>
  <section class="my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="home-wrapper">
            <div class="mb-5">
              <router-link to="/" class="d-block auth-logo">
                <img
                  src="@/assets/images/logo-dark.png"
                  alt=""
                  height="20"
                  class="auth-logo-dark mx-auto"
                />
                <img
                  src="@/assets/images/logo-light.png"
                  alt=""
                  height="20"
                  class="auth-logo-light mx-auto"
                />
              </router-link>
            </div>

            <h2 class="my-5">Payment unsuccessful</h2>
            <p>
              Please
              <router-link to="/">click here</router-link>
              if you are not redirected automatically.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
